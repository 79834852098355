import React, { useMemo, useCallback, useState } from 'react'
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table'
import { useControlledQuery } from 'src/components/table-controls/table-controls'
import PageHeader from '../page-header/page-header'
import { PageMaxWidth } from '../util-components/util-components'
import Panel from '../panel/panel'
import Icon from '../icon/icon'
import * as styles from './tournament-directors.module.less'
import Button from '../button/button'
import { navigate } from 'gatsby'
import { GET_TOURNAMENT_DIRECTORS } from './tournament-directors-queries'
import {
  GetTournamentDirectors,
  GetTournamentDirectors_paginatedOrganisationContactRelationships_items as TournamentDirector,
  GetTournamentDirectorsVariables
} from 'src/graphql-types/GetTournamentDirectors'
import { useTranslation } from 'react-i18next'
import { useOrgId } from 'src/apollo/local-state'
import SafePlayStatus from '../safeplay-status/safeplay-status'
import { RegisteredStatus } from '../tournament-director-attributes/tournament-director-attributes'
import SuccessNotification from '../success-notification/success-notification'
import { Operator } from 'src/graphql-types/globalTournamentTypes'
import TournamentDirectorFilters, {
  TournamentDirectorsFilters
} from '../tournament-directors-filters/tournament-director-filters'
import ROUTES from 'src/utils/routes'
import { tournamentsClient } from 'src/apollo/client'
import { getClientConfig } from 'src/config/config'

const TournamentDirectors: React.FC = () => {
  const orgId = useOrgId()
  const initialFilter = useMemo<TournamentDirectorsFilters>(
    () => ({
      firstName: '',
      lastName: ''
    }),
    []
  )
  const [filter, setFilter] = useState<any>(initialFilter)

  const filters = [
    {
      operator: Operator.CONTAINS,
      property: 'contact.firstName',
      values: filter.firstName
    },
    {
      operator: Operator.CONTAINS,
      property: 'contact.lastName',
      values: filter.lastName
    },
    { operator: Operator.EQUALS, values: ['DIRECTOR'], property: 'type' },
    { operator: Operator.NOT_IN, values: ['REVOKED'], property: 'status' }
  ]

  const getTotalItems = useCallback(
    (d: GetTournamentDirectors) => d.paginatedOrganisationContactRelationships.totalItems,
    []
  )
  const getSortProperty = useCallback(key => {
    if (key === 'status') {
      return 'contact.userId'
    }
    return `contact.${key}`
  }, [])

  const { data, loading, error, controlProps } = useControlledQuery<
    GetTournamentDirectors,
    GetTournamentDirectorsVariables,
    TournamentDirector
  >(GET_TOURNAMENT_DIRECTORS, {
    client: tournamentsClient,
    variables: { orgId, filters },
    getTotalItems,
    getSortProperty
  })

  const directors = useMemo(() => {
    return data?.paginatedOrganisationContactRelationships.items.filter(c => !!c.contact)
  }, [data])
  const { t } = useTranslation()

  const columns = useMemo<DataCols<TournamentDirector>>(() => {
    return [
      { key: 'firstName', title: t('first name'), getValue: td => td.contact?.firstName },
      { key: 'lastName', title: t('last name'), getValue: td => td.contact?.lastName },
      { key: 'emailAddress', title: t('email address'), getValue: td => td.contact?.emailAddress },
      { key: 'status', title: t('status'), getValue: td => <RegisteredStatus director={td} /> },
      {
        key: 'safePlay',
        title: t('safe play'),
        hidden: !getClientConfig().safeplay,
        getValue: td => <SafePlayStatus status={td.contact?.safePlay?.status} />
      }
    ]
  }, [t])

  const onClick = useCallback((d: TournamentDirector) => {
    navigate(`/tournaments/directors/${d.id}`)
  }, [])

  return (
    <PageMaxWidth>
      <PageHeader title={t('tournament directors')} />
      <Panel
        title={<TournamentDirectorFilters onFilterChange={setFilter} initFilter={initialFilter} />}
        headerEndContent={
          <Button onClick={() => navigate('/tournaments/directors/invite')}>
            <Icon name="sm-add" className={styles.addIcon} />
            {t('invite director')}
          </Button>
        }
      >
        <AdminTable
          error={error?.message}
          loading={loading}
          controls={controlProps}
          columns={columns}
          data={directors}
          onRowClick={onClick}
          spacing={{ margins: { md: 'top' } }}
        />
      </Panel>
      <SuccessNotification linkLabel={t('view profile')} atUrl={ROUTES.TOURNAMENT_DIRECTORS} />
    </PageMaxWidth>
  )
}

export default TournamentDirectors
