import React from 'react'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import * as styles from './filter-set.module.less'
import { SpacingProps, useSpacing } from 'src/hooks/spacing'
import cx from 'classnames'

const FilterSet: React.FC<SpacingProps> = ({ children, spacing }) => {
  const spacingClass = useSpacing(spacing)
  return <div className={cx(styles.filterSet, spacingClass)}>{children}</div>
}

interface FilterProps {
  label?: string
}

export const Filter: React.FC<FilterProps> = ({ label, children }) => {
  return (
    <div className={styles.filter}>
      <label>{label}</label>
      <div className={styles.filterInputs}>{children}</div>
    </div>
  )
}

export default FilterSet
