import React, { useState, useEffect } from 'react'
import { NavigationState } from 'src/utils/typedefs/navigation'
import { navigate, Link } from 'gatsby'
import FloatingNotification from '../floating-notification/floating-notification'
import { useLocation } from '@reach/router'
import * as styles from './success-notificaiton.module.less'

interface Props {
  atUrl: string
  linkLabel?: string
  variant?: 'success' | 'neutral'
  hideAfterMs?: number
}

const SuccessNotification: React.FC<Props> = ({ atUrl, linkLabel, hideAfterMs, variant = 'neutral' }) => {
  const [open, setOpen] = useState(true)
  const navStatePopup = (useLocation().state as NavigationState)?.popup
  const [popup, setPopup] = useState<NavigationState['popup']>()

  // Make a copy of the popup from the nav state and then clear nav state
  // with a clean navigate(). This prevents the popup reappearing when
  // navigating back to this page (e.g. back button)
  useEffect(() => {
    if (navStatePopup) {
      setPopup(navStatePopup)
      navigate(atUrl)

      hideAfterMs && setTimeout(() => {
        setOpen(false)
      }, hideAfterMs)
    }
  }, [navStatePopup, setPopup])

  return (
    <>
      {popup && open && (
        <FloatingNotification
          message={popup.message}
          icon={{ name: 'sm-tick', className: styles.tick }}
          actionButton={
            popup.link && (
              <Link to={popup.link} onClick={() => setOpen(false)}>
                {linkLabel}
              </Link>
            )
          }
          onClose={() => setOpen(false)}
          variant={variant}
        />
      )}
    </>
  )
}

export default SuccessNotification